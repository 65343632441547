<template>
  <div
    class="ma-4"
    style="min-height: 700px"
  >
    <v-row justify="space-between" >

      <v-radio-group
        v-model="calendarView"
        row
      >
        <v-radio
          label="Show Tabular View"
          :value="false"
        ></v-radio>
        <v-radio
          label="Show Calendar View"
          :value="true"
        ></v-radio>
      </v-radio-group>

      <h3 v-if="calendarView" class="pt-3 mt-2">
        {{this.title}}
      </h3>

      <div class="d-flex pt-3" v-if="calendarView">
        <v-btn
          class="mr-3"
          color="primary"
          @click="prev"
        >
        Previous Month
        </v-btn>

        <v-btn
          color="primary"
          @click="next"
        >
        Next Month
        </v-btn>
      </div>
    </v-row>

    <v-row class="pt-4">   
      <v-calendar
        v-if="calendarView"
        show-month-on-first
        v-model="currentMonth"
        ref="calendar"
        type="month"
        :events="events"
        style="min-height: 600px"
        :event-overlap-threshold="30"
        @change="updateRange"
      >
      </v-calendar>

      <v-card v-else style="width: 100%">
        <v-card-title>
          Course Schedule
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :items-per-page="itemsPerPage"
          :headers="headers"
          :items="courseSubjects"
          hide-default-footer
          :search="search"
        >

        <template v-slot:[`item.subject`]="props">
          <span class="pa-1" :style="`${props.item.color}; color:#fff;`" >{{props.item.subject.subjectName }} </span>
        </template>

        <template v-slot:[`item.endDate`]="props">
          <span>{{props.item.endDate | formatDate}} </span>
        </template>

        <template v-slot:[`item.startDate`]="props">
          <span>{{props.item.startDate | formatDate}} </span>
        </template>

        <template v-slot:[`item.numDays`]="props">
          <span>{{props.item | daysDiff}} </span>
        </template>

        <template v-slot:[`item.numberOfSessions`]="props">
          <span>{{props.item.numberOfSessions }} </span>
        </template>

        <template v-slot:[`item.venue`]="props">
          <span>{{props.item.subject.id | venue}} </span>
        </template>

        </v-data-table>
      </v-card>

    </v-row>
  </div>
</template>

<script>

import randomColor from 'randomcolor'

export default {
  mounted() {
    this.$store.dispatch('fetchCourseSubjects')
  },
  filters: {
    daysDiff: function (item) {
      const from  = new Date(item.startDate)
      const to = new Date(item.endDate)
      return Math.round((to-from)/(1000*60*60*24)) + 1;
    },
    formatDate: function(dt) {
      const date = new Date(dt)
      const day = date.toLocaleString('default', { day: '2-digit' });
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.toLocaleString('default', { year: 'numeric' });
      return day + '-' + month + '-' + year;
    },
    venue: function (subjectId){

      if(subjectId == 1){
        return 'IIMB Campus'
      }

      if(subjectId == 13){
        return 'IIMB Campus'
      }

      if(subjectId == 14){
        return 'IIMB Campus'
      }

      return 'IIMB Campus & Distance Mode'
    }
  },
  data: () => {
    return {
      title: '',
      currentMonth: '',
      events: [],
      calendarView: true,
      itemsPerPage:25,

      search: '',
      headers: [
        {
          text: 'Module',
          align: 'start',
          sortable: false,
          value: 'subject',
          width: 120 
        },
        { text: 'Module Name', value: 'subject.subjectDescription' },
        { text: 'Start Date', value: 'startDate', width: 140 },
        { text: 'End Date', value: 'endDate', width: 140, },
        { text: '# Days', value: 'numDays' },
        { text: '# Sessions', value: 'numberOfSessions' },
        { text: 'Venue', value: 'venue' },
      ],
    };
  },
  methods:{
    prev(){
      this.$refs.calendar.prev()
    },
    next(){
      this.$refs.calendar.next()
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;

      let yr = this.start.year;
      let mn = this.start.month;

      const mNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      this.title = mNames[mn-1] + ", " + yr;
    }
  },
  computed:{
    courseSubjects() {

      if(!this.$store.getters.getCourseSubjects){
        return []
      }

      let courseSubjects = JSON.parse(JSON.stringify(this.$store.getters.getCourseSubjects))
      let newCourseSubjects = []

      for(let i=0; i<courseSubjects.length; i++){

        newCourseSubjects.push({
          color: "background-color:" + randomColor({luminosity: 'dark', hue: 'random', alpha: 1, seed: courseSubjects[i].subject.id * 42 }),
          ...courseSubjects[i]
        })
      }
      
      return newCourseSubjects
    },
  },
  watch:{
    courseSubjects(n,o) {

      if(n && n.length > 0){

        let events = []  
        this.events = []

        for(let i = 0; i < n.length; i++){
          let subjectName = `[${n[i].subject.subjectName}] ${n[i].subject.subjectDescription}`
          let startDate = n[i].startDate
          let endDate = n[i].endDate
          
          events.push({
            name: subjectName,
            start: startDate,
            end: endDate,
            color: randomColor({luminosity: 'dark', hue: 'random', alpha: 1, seed: n[i].subject.id * 42 })
          })
        }

        this.events = events
      }
    }
  }
}
</script>

<style>

  .v-data-table-header th {
    white-space: nowrap;
  }

</style>
